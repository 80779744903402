import axios, { AxiosRequestHeaders } from 'axios'
import { Notify } from 'vant'

const api = axios.create(
  {
    baseURL: 'https://api.print.num.show/',
    timeout: 10000,
    responseType: 'json',
  }
)

const getHeaders = () => {
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json;charset=utf-8',
    'Accept': 'application/json'
  }

  const token = localStorage.getItem('token')
  if (token) { 
    headers['x-api-key'] = token
  }

  return headers
}


api.interceptors.request.use(
  request => {

    request.headers = getHeaders()

    return request
  }
)

api.interceptors.request.use((req) => {

  req.headers = getHeaders()

  return req
})

api.interceptors.response.use(
  response => {
    if (response.data.code === 200) {
      return Promise.resolve(response.data)
    }

    if ( response.data.code === 401 ) {
      Notify({type: 'danger', message: '请登录后再操作'})
    }
    else {
      // 这里做错误提示
      Notify({type: 'danger', message: response.data.message})
    }
    return Promise.reject(response.data)
  },
  error => {
    let msg = error.message
    if (msg === 'Network Error') {
      msg = '后端网络故障'
    }
    else if (msg.includes('timeout')) {
      msg = '接口请求超时'
    }
    else if (msg.includes('Request failed with status code')) {
      msg = '接口' + msg.substr(msg.length - 3) + '异常'
    }
    Notify({type: 'danger', message: msg})
    return Promise.reject(error)
  }
)

export default api


