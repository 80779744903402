import { defineStore } from 'pinia'
import api from '@/api'

interface ImageState {
  id: number
  merchant_id: string
  name: string
  cate_id: number
  url: string
  is_vip: number
  sort: number
  status: string
  created_at: string
  updated_at: string
}




export interface ImageStoreState {
  loading: boolean
  images: ImageState[]
  activeIndex: number
}


export const ImageState = []

export const useImageStore = defineStore('images', {
  state: (): ImageStoreState => ({
    loading: false,
    images: [],
    activeIndex: 0
  }),
  getters: {},
  actions: {
    // 获取上传图片
    async getImages(index?: number) {
      this.images = []
      this.loading = true
      let params
      if (index && index > 0) {
        params = { params: { cate_id: index } }
      }
      else {
        params = { }
      }
      return await api.get(`v1/member/image`, params)
    },
    setIndex(num: number) {
      this.imageIndex = num
      console.log('num:' + num)
    },
    getIndex() { 
      return this.imageIndex
    },
    // 删除图片
    async delete(id: number): Promise<void> {
      return await api.delete(`v1/member/image/${id}`)
    }
  }
})