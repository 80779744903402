import Dexie from 'dexie'
import { databaseId } from '@/store/main'

import { Slide } from '@/types/slides'

import { LOCALSTORAGE_KEY_DISCARDED_DB } from '@/configs/storage'

export interface Snapshot {
  index: number
  slides: Slide[]
}

const databaseNamePrefix = 'IBTV'

// 删除失效/过期的数据库
// 应用关闭时（关闭或刷新浏览器），会将其数据库ID记录在 localStorage 中，表示该ID指向的数据库已失效
// 当应用初始化时，检查当前所有数据库，将被记录失效的数据库删除
// 另外，距离初始化时间超过1200小时的数据库也将被删除（这是为了防止出现因以外未被正确删除的库）
export const deleteDiscardedDB = async () => {
  const now = new Date().getTime()

  const localStorageDiscardedDB = localStorage.getItem(LOCALSTORAGE_KEY_DISCARDED_DB)

  const databaseNames = await Dexie.getDatabaseNames()
  const discardedDBNames = databaseNames.filter(name => {
    if (name.indexOf(databaseNamePrefix) === -1) return false
    const [prefix, id, time] = name.split('_')
    if (prefix !== databaseNamePrefix || !id || !time) return true

    return false
  })

  for (const name of discardedDBNames) Dexie.delete(name)
  localStorage.removeItem(LOCALSTORAGE_KEY_DISCARDED_DB)
}

export class PPTistDB extends Dexie {
  public snapshots: Dexie.Table<Snapshot, number>



  public constructor() {
    const uid = localStorage.getItem('uid')
    const tid = localStorage.getItem('tid')
    const id = localStorage.getItem('id')

    console.log('DB-UTI:', uid, tid, id)


    super(`${databaseNamePrefix}_${uid}_${tid}_${id}`)

    this.version(1).stores({
      snapshots: '++id'
    })

    this.snapshots = this.table('snapshots')
  }
}

// export const db = new PPTistDB()