import { defineStore } from 'pinia'
import api from '@/api'

export interface StickCate { 
  id: number
  name: string
}


export interface StickState {
  id: number
  name: string
  cate_id: number
  url: string
  is_vip: number
}

export interface STState { 
  loading: boolean
  cates: StickCate[]
  data: StickState[]
}

export const useStickStore = defineStore('stick', {
  state: (): STState => ({
    loading: false,
    cates: [],
    data: [],
  }),
  getters: {
  },
  actions: {
    async getCate() {
      const { data } = await api.get(`v2/style-cate`)
      this.cates = data
    },
    async getData(index: number) {
      this.musics = []
      this.loading = true
      let params
      if (index > 0) {
        params = { params: { cate_id: index } }
      }
      else {
        params = { }
      }
      await api.get(`v2/style`, params).then(res => {
        this.data = res.data
        this.loading = false
      })
    }
  }
})