// 音乐单项
export interface musicItemState { 
  id: number
  name: string
  url: string
}

// 默认音乐
export const music: musicItemState = {
  id: 0,
  name: '',
  url: ''
}