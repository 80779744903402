import { defineStore } from 'pinia'
import Dexie, { IndexableTypeArray } from 'dexie'
import { PPTistDB, deleteDiscardedDB, Snapshot } from '@/utils/database'
import { LOCALSTORAGE_KEY_DISCARDED_DB } from '@/configs/storage'


import { useSlidesStore } from './slides'
import { useMainStore } from './main'


export interface ScreenState {
  snapshotCursor: number
  snapshotLength: number
}

export const useSnapshotStore = defineStore('snapshot', {
  state: (): ScreenState => ({
    snapshotCursor: -1, // 历史快照指针
    snapshotLength: 0, // 历史快照长度
  }),

  getters: {
    canUndo(state) {
      return state.snapshotCursor > 0
    },
    canRedo(state) {
      return state.snapshotCursor < state.snapshotLength - 1
    }
  },

  actions: {
    setSnapshotCursor(cursor: number) {
      this.snapshotCursor = cursor
    },
    setSnapshotLength(length: number) {
      this.snapshotLength = length
    },

    async initSnapshotDatabase() {
      // 删除历史数据
      await deleteDiscardedDB()
    },

    /**
     * 清空指定数据库
     */
    async deleteAllCacheDb(tableName: string) {
      const databaseNames = await Dexie.getDatabaseNames()
      for (const name of databaseNames) {
        console.log(name)
        if (name === tableName) {
          Dexie.delete(name)
        }
      }
    },

    // 添加
    async addSnapshot() {
      const slidesStore = useSlidesStore()

      // 获取当前indexeddb中全部快照的ID
      const db = new PPTistDB()
      const allKeys = await db.snapshots.orderBy('id').keys()
  
      let needDeleteKeys: IndexableTypeArray = []
  
      // 记录需要删除的快照ID
      // 若当前快照指针不处在最后一位，那么再添加快照时，应该将当前指针位置后面的快照全部删除，对应的实际情况是：
      // 用户撤回多次后，再进行操作（添加快照），此时原先被撤销的快照都应该被删除
      if (this.snapshotCursor >= 0 && this.snapshotCursor < allKeys.length - 1) {
        needDeleteKeys = allKeys.slice(this.snapshotCursor + 1)
      }
  
      // 添加新快照
      const snapshot = {
        index: slidesStore.slideIndex,
        slides: slidesStore.slides,
      }
      await db.snapshots.add(snapshot)
  
      // 计算当前快照长度，用于设置快照指针的位置（此时指针应该处在最后一位，即：快照长度 - 1）
      let snapshotLength = allKeys.length - needDeleteKeys.length + 1
  
      // 快照数量超过长度限制时，应该将头部多余的快照删除
      const snapshotLengthLimit = 10
      if (snapshotLength > snapshotLengthLimit) {
        needDeleteKeys.push(allKeys[0])
        snapshotLength--
      }
  
      // 快照数大于1时，需要保证撤回操作后维持页面焦点不变：也就是将倒数第二个快照对应的索引设置为当前页的索引
      // https://github.com/pipipi-pikachu/PPTist/issues/27
      if (snapshotLength >= 2) {
        db.snapshots.update(allKeys[snapshotLength - 2] as number, { index: slidesStore.slideIndex })
      }
  
      await db.snapshots.bulkDelete(needDeleteKeys)
  
      console.log('当前快照：' + (snapshotLength - 1))
      this.setSnapshotCursor(snapshotLength - 1)
      this.setSnapshotLength(snapshotLength)
    },

    // 临时文件
    async tableNum(): Promise<number> {
      const db = new PPTistDB()
      
      const allKeys = await db.snapshots.orderBy('id').keys()
      return allKeys.length as number
    },

    // 获取历史数据
    async lastData(tid: number) { 
      const slidesStore = useSlidesStore()
      const mainStore = useMainStore()
      const db = new PPTistDB()
      // 获取当前indexeddb中全部快照的ID
      const allKeys = await db.snapshots.orderBy('id').keys()
      console.log('allKeys.length: ' + allKeys.length)
      if (allKeys.length >= 1) {
        // 计算当前快照长度，用于设置快照指针的位置（此时指针应该处在最后一位，即：快照长度 - 1）
        const snapshots: Snapshot[] = await db.snapshots.orderBy('id').toArray()
        const snapshot = snapshots[allKeys.length - 1]
        const { index, slides } = snapshot
        const slideIndex = index > slides.length - 1 ? slides.length - 1 : index
        slidesStore.setSlides(slides)
        slidesStore.updateSlideIndex(slideIndex)
        mainStore.setActiveElementIdList([])
        slides.map(item => { 
          if (item.viewportRatio) { 
            slidesStore.setViewportRatio(item.viewportRatio)
          }

          if (item.width) {
            slidesStore.width = item.width
          }
          if (item.height) {
            slidesStore.height = item.height
          }
          if (item.width && item.height) {
            const rate = Number(item.height) / item.width
            slidesStore.setViewportRatio(Number(rate.toFixed(6)))
          }
          if (item.music) { 
            slidesStore.setMusic(item.music)
          }
        })
      }
    },
    async unDo() {
      if (this.snapshotCursor <= 0) return

      const slidesStore = useSlidesStore()
      const mainStore = useMainStore()
      const db = new PPTistDB()
  
      const snapshotCursor = this.snapshotCursor - 1
      const snapshots: Snapshot[] = await db.snapshots.orderBy('id').toArray()
      const snapshot = snapshots[snapshotCursor]
      const { index, slides } = snapshot
  
      const slideIndex = index > slides.length - 1 ? slides.length - 1 : index
  
      slidesStore.setSlides(slides)
      slidesStore.updateSlideIndex(slideIndex)
      this.setSnapshotCursor(snapshotCursor)
      mainStore.setActiveElementIdList([])
    },
  
    async reDo() {
      if (this.snapshotCursor >= this.snapshotLength - 1) return

      const slidesStore = useSlidesStore()
      const mainStore = useMainStore()
      const db = new PPTistDB()
  
      const snapshotCursor = this.snapshotCursor + 1
      const snapshots: Snapshot[] = await db.snapshots.orderBy('id').toArray()
      const snapshot = snapshots[snapshotCursor]
      const { index, slides } = snapshot
  
      const slideIndex = index > slides.length - 1 ? slides.length - 1 : index
  
      slidesStore.setSlides(slides)
      slidesStore.updateSlideIndex(slideIndex)
      this.setSnapshotCursor(snapshotCursor)
      mainStore.setActiveElementIdList([])
    },
  },
})