import { RouteRecordRaw } from 'vue-router'


export const View: RouteRecordRaw = {
  name: 'View',
  path: '/',
  component: () => import('@/views/Mobile/MobileEditor/index.vue')
}

const constantRoutes = [
  View
]

export default constantRoutes