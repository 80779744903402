import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import routers from './routers'
import Vant, { ConfigProvider, Lazyload } from 'vant'
import 'vant/lib/index.css'

import '@/assets/iconfont/iconfont.css'
import 'prosemirror-view/style/prosemirror.css'
import 'animate.css'

import '@/assets/styles/prosemirror.scss'
import '@/assets/styles/global.scss'
import '@/assets/styles/antd.scss'

import Component from '@/plugins/component'
import Directive from '@/plugins/directive'

import Icon from '@/plugins/icon'


import {
  Modal,
  Dropdown,
  Drawer,
  Spin,
  Skeleton
} from 'ant-design-vue'

const app = createApp(App)
app.use(routers)

// app.component('Slider', Slider)
app.component('Modal', Modal)
app.component('Dropdown', Dropdown)
app.component('Drawer', Drawer)
app.component('Spin', Spin)
app.component('Skeleton', Skeleton)

app.use(Component)
app.use(Directive)
app.use(Vant)
app.use(ConfigProvider)
app.use(Lazyload)
app.use(Icon)

app.use(createPinia())
app.mount('#app')
