import { defineStore } from 'pinia'
import api from '@/api'
import { useSlidesStore } from './slides'
import { Slide } from '@/types/slides'

export interface ShareState {
  id: number
  merchant_id: string
  share_titles: string
  share_type: number
  target_model: string
  target_id: number
  status: number
  updated_at: number
  created_at: number
  data: string
  share_message: string
  url: string
  thumb: string
  is_share: number
  is_temp: number
  qq: number
  weixin: number
  star: number
  text_total: number
  image_total: number
  music_id: number
  share_total: number
  star_total: number
  view_total: number
  price_type: string
  num: number
}


export interface ShareStoreState { 
  loading: boolean
  id: number
  type: string
  data: {}
  targetData: {}
  all: ShareState[]
}

export const useShareStore = defineStore('share', {
  state: (): ShareStoreState => ({
    loading: false,
    id: 0,
    type: 'image',
    data: {},
    targetData: {},
    all: [],
  }),
  getters: {},
  actions: {
    // 获取所有分享
    async getShareAll() {
      this.loading = true
      const params = { }
      const { data } = await api.get(`v1/member/share`, params)
      this.all = data
      this.loading = false
    },

    // TODO 验证身份证
    checkId(uid: string, token: string) { 
      return true
    },

    // 获取单个文件
    async getShare(id: string) { 
      this.loading = true
      const { data } = await api.get(`v1/member/share/${id}`)
      this.data = data
      const slidesStore = useSlidesStore()
      const slideData: Slide[] = JSON.parse(data.data)
      slidesStore.setSlides(slideData)
      this.loading = false
    },

    //  获取模板信息
    async getTarget(id: string) { 
      this.loading = true
      const { data } = await api.get(`v1/goods/${id}`)
      this.targetData = data
      const slidesStore = useSlidesStore()
      const slideData: Slide[] = JSON.parse(data.data)
      slidesStore.setSlides(slideData)
      this.loading = false
    }
  }
})