import { useSnapshotStore } from '@/store'
import { defineStore } from 'pinia'
import tinycolor from 'tinycolor2'
import { conforms, omit } from 'lodash'
import api from '@/api'
import { Slide, SlideTheme, PPTElement, PPTAnimation } from '@/types/slides'
import { musicItemState, music } from '@/types/music'
import { slides } from '@/mocks/slides'
import { theme } from '@/mocks/theme'
import { layouts } from '@/mocks/layout'
import { message } from 'ant-design-vue'

interface RemoveElementPropData {
  id: string
  propName: string | string[]
}

interface UpdateElementData {
  id: string | string[]
  props: Partial<PPTElement>
}

interface FormatedAnimation {
  animations: PPTAnimation[]
  autoNext: boolean
}

export interface SlidesState {
  id: number
  tid: number
  uid: number
  token?: string
  t: 'image' | 'build' | 'video'
  spin: boolean
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  theme: SlideTheme
  slides: Slide[]
  slideIndex: number
  viewportRatio: number
  width?: number
  height?: number
  music?: musicItemState
}


export const useSlidesStore = defineStore('slides', {
  state: (): SlidesState => ({
    id: 0,
    tid: 0,
    uid: 0,
    token: '',
    t: 'image',
    spin: false, // 全局加载
    data: {},
    title: '', // 标题
    theme: theme, // 主题样式
    slides: slides, // 幻灯片页面数据
    slideIndex: 0, // 当前页面索引
    viewportRatio: 0.5625, // 可是区域比例，默认16:9 = 0.5625  9:16 = 1.777
    width: 0,
    height: 0,
    music: music
  }),

  getters: {
    currentSlide(state) {
      return state.slides[state.slideIndex]
    },
    currentSlideAnimations(state) {
      const currentSlide = state.slides[state.slideIndex]
      if (!currentSlide?.animations) return []

      const els = currentSlide.elements
      const elIds = els.map(el => el.id)
      return currentSlide.animations.filter(animation => elIds.includes(animation.elId))
    },

    // 格式化的当前页动画
    // 将触发条件为“与上一动画同时”的项目向上合并到序列中的同一位置
    // 为触发条件为“上一动画之后”项目的上一项添加自动向下执行标记
    formatedAnimations(state) {
      const currentSlide = state.slides[state.slideIndex]
      if (!currentSlide?.animations) return []

      const els = currentSlide.elements
      const elIds = els.map(el => el.id)
      const animations = currentSlide.animations.filter(animation => elIds.includes(animation.elId))

      const formatedAnimations: FormatedAnimation[] = []
      for (const animation of animations) {
        if (animation.trigger === 'click' || !formatedAnimations.length) {
          formatedAnimations.push({ animations: [animation], autoNext: false })
        }
        else if (animation.trigger === 'meantime') {
          const last = formatedAnimations[formatedAnimations.length - 1]
          last.animations = last.animations.filter(item => item.elId !== animation.elId)
          last.animations.push(animation)
          formatedAnimations[formatedAnimations.length - 1] = last
        }
        else if (animation.trigger === 'auto') {
          const last = formatedAnimations[formatedAnimations.length - 1]
          last.autoNext = true
          formatedAnimations[formatedAnimations.length - 1] = last
          formatedAnimations.push({ animations: [animation], autoNext: false })
        }
      }
      return formatedAnimations
    },

    layouts(state) {
      const {
        themeColor,
        fontColor,
        fontName,
        backgroundColor,
      } = state.theme

      const subColor = tinycolor(fontColor).isDark() ? 'rgba(230, 230, 230, 0.5)' : 'rgba(180, 180, 180, 0.5)'

      const layoutsString = JSON.stringify(layouts)
        .replaceAll('{{themeColor}}', themeColor)
        .replaceAll('{{fontColor}}', fontColor)
        .replaceAll('{{fontName}}', fontName)
        .replaceAll('{{backgroundColor}}', backgroundColor)
        .replaceAll('{{subColor}}', subColor)
      return JSON.parse(layoutsString)
    },
  },

  actions: {
    setTheme(themeProps: Partial<SlideTheme>) {
      this.theme = { ...this.theme, ...themeProps }
    },
    setViewportRatio(viewportRatio: number) {
      this.viewportRatio = viewportRatio
    },
    setWidth(width: number) {
      this.width = width
    },
    setHeight(height: number) {
      this.height = height
    },
    setSpin(spin: boolean) {
      this.spin = spin
    },
    setTid(tid: number) {
      this.tid = tid
    },
    setToken(token: string) {
      this.token = token
    },
    setMusic(item: musicItemState) {
      this.music = item
      this.updateSlide({music: this.music})
    },
    // TODO 验证身份证
    checkId(uid: string, token: string) { 
      return true
    },
    // 获取选中音乐
    getMusic() {
      return this.music
    },

    //  获取模板信息
    getTarget(id: number) {
      api.get(`v2/video/${id}`).then(res => { 
        console.log('getTarget api')
        const slideData: Slide[] = res.data.data ? JSON.parse(res.data.data) : []
        console.log('getTarget:' + id)
        console.log(slideData)
        this.setSlides(slideData)
        slideData.map(item => {
          if (item.viewportRatio) {
            this.setViewportRatio(item.viewportRatio)
          }
          if (item.width) {
            this.width = item.width
          }
          if (item.height) {
            this.height = item.height
          }
          if (item.width && item.height) {
            const rate = Number(item.height) / item.width
            this.setViewportRatio(Number(rate.toFixed(6)))
          }
          if (item.music) {
            this.setMusic(item.music)
          }
          else {
            this.setMusic(music)
          }
        })
      })
    },


    // 获取单个文件
    async getShare(id: string) { 
      const { data } = await api.get(`v1/member/share/${id}`)
      const slideData: Slide[] = JSON.parse(data.data)
      this.setSlides(slideData)
      slideData.map(item => {
        if (item.viewportRatio) {
          this.setViewportRatio(item.viewportRatio)
        }
        if (item.width) {
          this.width = item.width
        }
        if (item.height) {
          this.height = item.height
        }
        if (item.width && item.height) {
          const rate = Number(item.height) / item.width
          this.setViewportRatio(Number(rate.toFixed(6)))
        }
        if (item.music) {
          this.setMusic(item.music)
        }
      })
    },

    // 获取所有字体分类
    async getFontCate() { 
      return await api.get('v2/font-cate')
    },


    // 获取所有字体
    async getAllFont() { 
      return await api.get('v2/font/all')
    },

    // 区分中文英文
    async getFontAllType(t: string) { 
      return await api.get('v2/font?per-page=150&tid=' + this.tid + '&t=' + t)
    },

    // 一级字体
    async getIndexFont(id: number) {
      return await api.get('v2/font/' + id + '?t=video' )
    },

    // 动态加载
    async getFontUrl(name: string) {
      return await api.get('v2/font/search?keyword=' + name)
    },

    // 获取数据
    getData(id: number) {
      this.id = id
      this.spin = true
      if (id === undefined || id <= 0) return
      api.get(`v2/video/` + id, {}).then(response => {
        const data = response.data.data
        if (data) {

          console.log('getData:' + id)

          const slideData: Slide[] = JSON.parse(data)
          this.setSlides(slideData)

          this.data = response.data
          this.spin = false
          this.title = response.data.share_title
          slideData.map(item => { 
            if (item.viewportRatio) { 
              this.setViewportRatio(item.viewportRatio)
            }

            // 动态计算比例
            if (item.width) {
              this.width = item.width
            }
            if (item.height) {
              this.height = item.height
            }
            if (item.width && item.height) {
              const rate = Number(item.height) / item.width
              this.setViewportRatio(Number(rate.toFixed(6)))
            }
            if (item.music) {
              this.setMusic(item.music)
            }
            else { 
              this.setMusic(music)
            }
          })
        }
        else {
          const slideData: Slide[] = []
          this.setSlides(slideData)
          slideData.map(item => { 
            if (item.viewportRatio) { 
              this.setViewportRatio(item.viewportRatio)
            }
            if (item.width) {
              this.width = item.width
            }
            if (item.height) {
              this.height = item.height
            }
            if (item.width && item.height) {
              const rate = Number(item.height) / item.width
              this.setViewportRatio(Number(rate.toFixed(6)))
              item.viewportRatio = Number(rate.toFixed(6))
            }
            if (item.music) {
              this.setMusic(item.music)
            }
          })
          message.warning('无数据')
          this.spin = false
        }
        const snapshotStore = useSnapshotStore()
        snapshotStore.lastData(this.tid)
      })
    },


    // 重置数据
    resetData(id: number): void {
      const rows = this.data
      const target_id = rows.target_id
      const target_model = rows.target_model

      // 临时文件重置数据
      if (!this.id) {
        // 清空指定表
        const uid = localStorage.getItem('uid')
        const tid = localStorage.getItem('tid')
        const id = localStorage.getItem('id')
        const tableName = `IBT_${uid}_${tid}_${id}`

        console.log('del table name: ' + tableName)

        const snapshotStore = useSnapshotStore()
        snapshotStore.deleteAllCacheDb(tableName).then(() => {
          this.getTarget(this.tid)
        })
      }
      else {

        console.log(rows)

        let url = 'v2/share/'
        if (this.t === 'video') {
          url = 'v2/video/'
        }

        api.post(`${url}reset`, { id: this.id, target_id: target_id }).then(res => {
          const data = res.data.data
          if (data) {
            const slideData: Slide[] = JSON.parse(data)
            this.setSlides(slideData)
            this.data = res.data
            this.setMusic(music)
            message.success('重置数据加载完成')
            this.title = res.data.share_title
          }
          else {
            const slideData: Slide[] = []
            this.setSlides(slideData)
            this.setMusic(music)
            message.warning('无数据')
          }
        })
      }
    },

    // 同步数据
    async syncData() {
      const slideIndex = this.slideIndex
      const slide = this.slides[slideIndex]
      // 忽略默认模板
      if (slide.id === 'test-slide-1') {
        return false
      }
      // 统计图文各自数量
      const { textTotal, imageTotal } = this.getToal()
      const slideData = JSON.stringify(this.slides)
      console.log('sync music:')
      console.log(this.music)

      if (slideData && this.id) {
        let url = 'v2/share/'
        if (this.t === 'video') {
          url = 'v2/video/'
        }
        return await api.put(`${url}${this.id}`, {
          data: slideData,
          music_id: this.music.id,
          'text_total': textTotal,
          'image_total': imageTotal
        })
      }
    },

    // 生成保存到数据库的临时文件
    async generateNetData() { 
      console.log(this.id)
      if (this.id > 0) return
      const slideData = JSON.stringify(this.slides)
      // 新模板生成
      return await api.post('v1/member/share', {
        target_id: this.tid,
        data: slideData,
        music_id: this.music.id,
        is_temp: 0,
        target_model: this.t
      })
    },
    // 将url保存到数据库
    async saveFileData(id: number, url: Array<string>) {
      console.log('上传图片数组')
      return await api.put(`v2/share/${id}`, {
        thumb: JSON.stringify(url)
      })
    },
    // 设置模板数据
    setSlides(slides: Slide[]) {
      this.slides = slides
    },

    // 获取模板的文字和图片数量
    getToal() {
      const slides = this.slides
      const pageTotal = slides.length
      let textTotal = 0
      let imageTotal = 0
      slides.forEach(slide => { 
        slide.elements.map(slide => {
          if (slide.type === 'text') { 
            textTotal += 1
          }
          if (slide.type === 'image') { 
            imageTotal += 1
          }
        })
      })
      return {pageTotal, textTotal, imageTotal}
    },

    // 获取数量
    total() {
      return this.slides.length
    },
  
    addSlide(slide: Slide | Slide[]) {
      const slides = Array.isArray(slide) ? slide : [slide]
      const addIndex = this.slideIndex + 1
      this.slides.splice(addIndex, 0, ...slides)
      this.slideIndex = addIndex
    },
  
    updateSlide(props: Partial<Slide>) {
      const slideIndex = this.slideIndex
      this.slides[slideIndex] = { ...this.slides[slideIndex], ...props }
    },
  
    deleteSlide(slideId: string | string[]) {
      const slidesId = Array.isArray(slideId) ? slideId : [slideId]
  
      const deleteSlidesIndex = []
      for (let i = 0; i < slidesId.length; i++) {
        const index = this.slides.findIndex(item => item.id === slidesId[i])
        deleteSlidesIndex.push(index)
      }
      let newIndex = Math.min(...deleteSlidesIndex)
  
      const maxIndex = this.slides.length - slidesId.length - 1
      if (newIndex > maxIndex) newIndex = maxIndex
  
      this.slideIndex = newIndex
      this.slides = this.slides.filter(item => !slidesId.includes(item.id))
    },
  
    updateSlideIndex(index: number) {
      this.slideIndex = index
    },
  
    addElement(element: PPTElement | PPTElement[]) {
      const elements = Array.isArray(element) ? element : [element]
      const currentSlideEls = this.slides[this.slideIndex].elements
      const newEls = [...currentSlideEls, ...elements]
      this.slides[this.slideIndex].elements = newEls
    },

    deleteElement(elementId: string | string[]) {
      const elementIdList = Array.isArray(elementId) ? elementId : [elementId]
      const currentSlideEls = this.slides[this.slideIndex].elements
      const newEls = currentSlideEls.filter(item => !elementIdList.includes(item.id))
      this.slides[this.slideIndex].elements = newEls
    },

    updateElement(data: UpdateElementData) {
      const { id, props } = data
      const elIdList = typeof id === 'string' ? [id] : id
      const slideIndex = this.slideIndex
      const slide = this.slides[slideIndex]
      const elements = slide.elements.map(el => {
        return elIdList.includes(el.id) ? { ...el, ...props } : el
      })
      this.slides[slideIndex].elements = (elements as PPTElement[])
    },

    removeElementProps(data: RemoveElementPropData) {
      const { id, propName } = data
      const propsNames = typeof propName === 'string' ? [propName] : propName

      const slideIndex = this.slideIndex
      const slide = this.slides[slideIndex]
      const elements = slide.elements.map(el => {
        return el.id === id ? omit(el, propsNames) : el
      })
      this.slides[slideIndex].elements = (elements as PPTElement[])
    },
    // 单个base64图片上传
    async updateImage(imageData: string, format: string) { 
      // 上传base64需要解码，同时需要去掉前面 data:image/png;base64,
      const imageData2 = imageData.substring(22)
      return await api.post('v1/share/action?action=image', {
        extend: format,
        image: imageData2,
      })
    }

  }
})