import { defineStore } from 'pinia'
import api from '@/api'

interface VideoState {
  id: number
  merchant_id: string
  name: string
  url: string
  sort: number
  status: string
  created_at: string
  updated_at: string
}




export interface VideoStoreState {
  loading: boolean
  videos: VideoState[]
  activeIndex: number
}


export const VideoState = []

export const useVideoStore = defineStore('videos', {
  state: (): VideoStoreState => ({
    loading: false,
    videos: [],
    activeIndex: 0
  }),
  getters: {},
  actions: {
    // 获取上传图片
    async getAll(index?: number) {
      this.videos = []
      this.loading = true
      let params
      if (index && index > 0) {
        params = { params: { cate_id: index } }
      }
      else {
        params = { }
      }
      return await api.get(`v1/member/video`, params)
    },
    setIndex(num: number) {
      this.activeIndex = num
      console.log('num:' + num)
    },
    getIndex() { 
      return this.activeIndex
    },
    // 删除图片
    async delete(id: number): Promise<void> {
      return await api.delete(`v1/member/video/${id}`)
    }
  }
})