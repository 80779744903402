import { defineStore } from 'pinia'
import api from '@/api'

export interface effectCate { 
  id: number
  name: string
}

export interface effectCallback {
  ( res: effectState):void
}

export interface effectState {
  id: number
  name: string
  cate_id: number
  url: string
  is_vip: number
}

export interface eState {
  cates: effectCate[]
  musics: effectState[]
}

export const useEffectStore = defineStore('effect', {
  state: (): eState => ({
    cates: [],
    musics: [],
  }),
  getters: {
  },
  actions: {
    getCate() { 
      api.get(`v2/effect-cate`, {}).then(res => { 
        console.log(res)
        this.cates = res.data
      })
    },
    getMusics(id: number, callback: effectCallback) { 
      api.get(`v2/effect`, { params: { cate_id: id } }).then(res => { 
        console.log(res)
        this.musics = res.data
        callback(res.data)
      })
    }
  }
})