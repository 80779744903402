import { defineStore } from 'pinia'
import api from '@/api'

export interface MusicCate { 
  id: number
  name: string
}


export interface MusicState {
  id: number
  name: string
  cate_id: number
  url: string
  is_vip: number
}

export interface MState { 
  loading: boolean
  cates: MusicCate[]
  musics: MusicState[]
}

export const useMusicStore = defineStore('musics', {
  state: (): MState => ({
    loading: false,
    cates: [],
    musics: [],
  }),
  getters: {
  },
  actions: {
    async getCate() {
      const { data } = await api.get(`v2/music-cate`)
      this.cates = data
    },
    async getMusics(index: number) {
      this.musics = []
      this.loading = true
      let params
      if (index > 0) {
        params = { params: { cate_id: index } }
      }
      else {
        params = { }
      }
      await api.get(`v2/music`, params).then(res => {
        this.musics = res.data
        this.loading = false
      })
    }
  }
})